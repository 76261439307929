import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem } from '@chakra-ui/react';

const Life = () => {
  return (
    <Box padding={4}>
        <Heading as="h3" size="md">Life</Heading>
        <UnorderedList>
            <ListItem>Anh and I are both doing well here in Dallas Metroplex!</ListItem>
            <ListItem>Recent trips include Switzerland, Tahoe/Yosemite, Vietnam, Delaware Beach Week, as well as trips to see family in SC, NC, MD, and PA.</ListItem>
            <ListItem>We're taking some trips out west in 2025: Arizona, Idaho, and Montana. Currently looking at New Zealand for an international trip as well.</ListItem>
        </UnorderedList>
    </Box>
  )
}

export default Life;