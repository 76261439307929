const skills = [
    {
      category: 'Business Strategy',
      skills: [
        'Strategy Consulting',
        'B2B Growth Strategy',
        'Client Delivery & Mgmt',
        'AI Adoption',
        'Change Management',
        'Project and Team Mgmt',
        'Communicating Analytics',
        'Product Management',
        'Pricing Strategy',
        'Knowledge Management',
      ]
    },
    {
        category: 'Data Science + Engineering',
        skills: [
          'Data Architecture',
          'Database Design',
          'AI Agent Design with RAG',
          'Supervised Learning',
          'A/B Testing',
          'Optimization',
          'Forecasting',
          'Reinforcement Learning',
          'Spark',
        ]
      },
      {
        category: 'Languages & Software',
        skills: [
          'Node + React Web Development',
          'Azure & GCP',
          'Databricks',
          'SQL',
          'Python Data Science Stack',
          'Javascript',
          'MS Excel',
          'Notion',
        ]
      },
      
    // Add more objects as needed
  ];

  
  export {skills};