import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const LatestUpdate = () => {
  return (
    <Box padding={4}>
    <Heading as="h2" size="md">The Highlights:</Heading>
      <Text as="i">Last update: March 2025</Text>
      <UnorderedList>
        <ListItem><b>Where are you?</b> Still in Dallas, TX now for 6+ years!</ListItem>
        <ListItem><b>What's happening?</b>
            <UnorderedList>
                <ListItem>Anh and I are both doing well, traveling a fair bit to see family and see the world.</ListItem>
                <ListItem>I've rejoined Databricks as a Solution Architect! I am not taking any consulting gigs at the moment, but feel free to reach out if you'd like to discuss a project and I see who I could connect you with.</ListItem>
                <ListItem>I've built two web apps that I plan to keep around:
                  <UnorderedList>
                    <ListItem><Link as={RouterLink} color='teal.500' to="/forward">Forward</Link> to help people manage habits, projects, and goals all in one place.</ListItem>
                  <ListItem><Link as={RouterLink} color='teal.500' to="/intellectinbox">Intellect Inbox</Link> to provide people with high-quality daily educational lessons, focused on helping parents engage in educational topics with their kids.</ListItem>
                </UnorderedList>
                </ListItem>
                <ListItem>I recently did my 13th full marathon back in December (Dallas BMW). After running record miles in 2024 (almost 1,800 for the year), I'm shifting toward a more holistic workout strategy this year.</ListItem>
                <ListItem>I've got various other projects going on in the background, all highlighted on <Link as={RouterLink} color='teal.500' to="/">the Home Page!</Link></ListItem>
            </UnorderedList>
        </ListItem>
      </UnorderedList>
    </Box>
  )
}

export default LatestUpdate;