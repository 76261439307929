import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, Link, UnorderedList, ListItem } from '@chakra-ui/react';

const Hobbies = () => {
  return (
    <Box padding={4}>
        <Heading as="h3" size="md">Hobbies and Interests</Heading>
        <UnorderedList>
            <ListItem>My writing is all on Substack: 
              <UnorderedList>
              <ListItem><Link color='teal.500' href="https://mumma.substack.com" target="_blank">A Moment with Mumma</Link> - political and social commentary</ListItem>, 
              <ListItem><Link color='teal.500' href="https://hopforward.substack.com" target="_blank">The Human Optimization Project</Link> - tools and systems to live happier, healthier, and more productive lives </ListItem>
              <ListItem><Link color="teal.500" href="https://thebookofprinciples.substack.com" target="_blank">The Book of Principles</Link> - principles and ideas worth living by</ListItem>
              <ListItem><Link color="teal.500" href="https://bizlandia.substack.com" target="_blank">Bizlandia</Link> - commentary on business, tech, data, and AI</ListItem>
              <ListItem><Link color='teal.500' href="https://mummapicks.substack.com" target="_blank">Mumma Picks</Link> is the home of my machine learning model to predict the winner of NFL games, which has generated substantial profit in 2023 (76% ROI on the regular season)</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>I ran an all-time high of 1,770 miles in 2024, <Link color="teal.500" href="https://www.strava.com/athletes/10076524" target="_blank">Find me on Strava</Link> if you want to stay up on that!</ListItem>
            <ListItem>Other stuff: exploring ChatGPT and AI trends, gardening, being a fun uncle, and building out various tools and such.</ListItem>
        </UnorderedList>
    </Box>
  )
}

export default Hobbies;