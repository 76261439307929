const current = [
  {
    job_title: 'Solution Architect - Strategic Oil & Gas',
    category: 'Current',
    location: '[Remote] Dallas, TX',
    company: 'Databricks',
    start_date: '2024',
    end_date: 'Present',
    tenure: '1 year',
    company_info: 'Databricks provides the world\'s best all-in-one Data & Analytics Platform',
    company_website:'https://www.databricks.com',
    bullet_points:[
      'Supporting several large Oil & Gas companies in their data strategy and implementation of Databricks.',
      'Providing guidance on architectural decisions for their big data applications.',
      'Identifying key areas where Databricks can generate value for our clients.',
    ]
  },
    {
      job_title: 'Founder, Consultant',
      category: 'Current',
      location: '[Remote] Dallas, TX',
      company: 'Mumma Labs, LLC',
      start_date: '2023',
      end_date: 'Present',
      tenure: '3 years',
      company_info: 'We provide data & strategy consulting services while building software to make the world a better place.',
      company_website:'https://www.mumma.co',
      bullet_points:[
        'Developed Forward, a productivity web app launched in September 2023. Use it for free @ www.theforwardapp.com!',
        'Developed Intellect Inbox, an educational app powered by GenAI to provide lessons and courses curated to the individual. Try it for free @ www.intellect.inbox!',
        'Advised 50-person stealth-stage SaaS startup on professional services strategy, business value assessment, and growth plan over a 3-month paid engagement.',
        'Advising several local startups on tech and data strategy.',
      ]
    },
]

export {current};