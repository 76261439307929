import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem } from '@chakra-ui/react';

const ReachOut = () => {
  return (
    <Box padding={4}>
        <Heading as="h3" size="md">Hit me up if?</Heading>
            <UnorderedList>
              <ListItem>You are/want to come visit Dallas - we have plenty of room and are happy to host!</ListItem>
              <ListItem>You want to bat around a startup idea or business concept, always happy to bat around ideas.</ListItem>
              <ListItem>You want to run a marathon or a half marathon somewhere & are getting a group together.</ListItem>
              <ListItem>For any other reason! If you don't have my contact info, you can use that fancy "Contact" box at the top of the page!</ListItem>
            </UnorderedList>
        </Box>
  )
}

export default ReachOut;