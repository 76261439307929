import React from 'react';
import { Box, Hr, Heading, Text, Flex, Button, ButtonGroup, UnorderedList, ListItem, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Business = () => {
  return (
    <Box padding={4}>
        <Heading as="h3" size="md">Business</Heading>
        <UnorderedList>
          <ListItem>I rejoined Databricks as a Solution Architect! I'll be working with our Oil & Gas vertical, and I'm very excited to be working at one of the best companies on the planet.</ListItem>
          <ListItem>As a result, my personal efforts will be on the back burner, but I am excited about Databricks and everything they are working on!</ListItem>
          <ListItem>Forward is still around and a great way to organize your life! Slowly working on a new dashboard that will integrate a calendar component.</ListItem>
          <ListItem>Intellect Inbox is still around too and a great way to learn things. Give it a shot!</ListItem>
        </UnorderedList>
    </Box>
  )
}

export default Business;