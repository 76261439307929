import React, {useState, useEffect, useContext} from "react";
import {
  ChakraProvider,
} from '@chakra-ui/react';
import theme from "./general/theme";
import { Route, Routes, useLocation} from 'react-router-dom';
import  HomePage  from './home/pages/HomePage.jsx';
import About from "./about/pages/About";
import  Expound  from './expound/pages/Expound.jsx';
import Resume from "./resume/pages/Resume";
import ArchitectureResume from "./resume/pages/ArchitectureResume";
import Now from "./now/pages/Now";

import Math24 from "./math24/pages/Math24";
import Consulting from "./consulting/pages/Consulting.jsx";

import God from "./gtd/pages/God";
import BlogRedirect from "./general/redirects/BlogRedirect";
import IntellectRedirect from "./general/redirects/IntellectRedirect";
import ForwardRedirect from "./general/redirects/ForwardRedirect";
import ArrMain from "./arr_app/ArrMain.jsx";
import ConsultingData from "./consulting/pages/ConsultingData.jsx";
import ConsultingSaaS from "./consulting/pages/ConsultingSaaS.jsx";
import B2BGrowth from "./consulting/pages/B2BGrowth.jsx";
import PriceOpt from "./consulting/pages/PriceOpt.jsx";
import CsAttrition from "./consulting/pages/CsAttrition.jsx";

import Privacy from "./docs/Privacy.jsx";
import TermsOfService from "./docs/TermsOfService.jsx";

import PickemMain from "./pickem/pages/PickemMain.jsx";
import LeaguePage from "./pickem/pages/LeaguePage.jsx";
import EventPage from "./pickem/pages/EventPage.jsx";
import PicksPage from "./pickem/pages/PicksPage.jsx";

function App() {
    //Upon load, try to set a session:
    const [session, setSession] = useState(null)
    const [loadingSession, setLoadingSession] = useState(true);
    const location = useLocation();


    //Change Site Title based on location (just forward for now!)
    useEffect(() => {
      if (location.pathname.includes('/arr')) {
        document.title = 'MRR Calculator';
      }
      else if (location.pathname.includes('/mrr')) {
        document.title = 'MRR Calculator';
      }
      else if (location.pathname.includes('/expound')) {
        document.title = 'Expound';
      }
      else if (location.pathname.includes('/math24')) {
        document.title = 'Math 24 Solver';
      }
      else if (location.pathname.includes('/resume')) {
        document.title = 'Ben Mumma\'s Resume';
      }
      else if (location.pathname.includes('/now')) {
        document.title = 'Ben\'s Now Page';
      }
      else if (location.pathname.includes('/about')) {
        document.title = 'About Mumma Labs';
      }
      else if (location.pathname.includes('/consulting')) {
        document.title = 'Mumma Labs Consulting';
      }
      else if (location.pathname.includes('/intellectinbox')) {
        document.title = 'Intellect Inbox';
      }
      else if (location.pathname.includes('/pickem')) {
        document.title = 'Mumma\'s Pick\'em';
      }
      else if (location.pathname.includes('/minigolf')) {
        document.title = 'Mini Golf';
      }
      else {
        document.title = 'Mumma Labs';
      }
    }, [location]);

    //Change Favicon based on location
    

  const [webState, setWebState] = useState({})


  return (
    <ChakraProvider theme={theme}>
      <Routes>
            <Route path="/" element={<HomePage session={session}/>} />

            <Route path="/docs/forward/privacy" element={<Privacy  session={session}/>} />
            <Route path="/docs/forward/tos" element={<TermsOfService session={session} />} />

            <Route path="/"  element={<HomePage session={session}/>} />

            <Route path="/blog" element={<BlogRedirect />}  />
            <Route path="/blog/*" element={<BlogRedirect />}  />
            <Route path="/intellectinbox" element={<IntellectRedirect />}  />
            <Route path="/intellectinbox/*" element={<IntellectRedirect />}  />
            <Route path="/forward" element={<ForwardRedirect />}  />
            <Route path="/forward/*" element={<ForwardRedirect />}  />

            
            <Route path="/pickem" element={<PickemMain />}  />
            <Route path="/pickem/league/:league_id" element={<LeaguePage />}  />
            <Route path="/pickem/event/:event_id" element={<EventPage />}  />
            <Route path="/pickem/event/:event_id/picks/:week_id/player/:player_id" element={<PicksPage />}  />

            <Route path="/about"  element={<About session={session}/>} />
            <Route path="/now"  element={<Now session={session}/>} />
            <Route path="/resume"  element={<Resume session={session}/>} />
            <Route path="/resume_arch"  element={<ArchitectureResume session={session}/>} />
            <Route path="/arr" element={<ArrMain session={session}/>} />
            <Route path="/mrr" element={<ArrMain session={session}/>} />
            <Route path="/expound" element={<Expound webState={webState}/>} />
            <Route path="/math24" element={<Math24 />} />

            <Route path="/consulting" element={<Consulting/>} />
            <Route path="/consulting/data" element={<ConsultingData/>} />
            <Route path="/consulting/saas" element={<ConsultingSaaS/>} />
            <Route path="/consulting/b2b" element={<B2BGrowth />} />
            <Route path="/consulting/priceopt" element={<PriceOpt />} />
            <Route path="/consulting/csattrition" element={<CsAttrition />} />

            {/*<Route path="/minigolf" element={<Minigolf />} />*/}

            <Route path="/god" element={<God session={session} />} />

            <Route path="/tos" element={<TermsOfService session={session} />} />
            <Route path="/privacy" element={<Privacy session={session} />} />

      </Routes>
    </ChakraProvider>
  );
}

export default App;
