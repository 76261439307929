const interests = [
    'Marathons (13 full marathons completed)',
    'Building things: digital & physical',
    'Golf',
    'Gardening',
    'Writing',
    'Learning languages',

];

  
  export {interests};